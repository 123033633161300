.list {
  margin-top: 30px; }

.loader {
  display: flex;
  justify-content: center;
  margin-top: 2rem; }

.addButton {
  align-items: center;
  display: flex;
  justify-content: center; }

.pagination {
  display: flex; }
