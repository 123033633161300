.wrapper {
  height: 50rem;
  min-height: 600px; }

.smallColumn {
  flex-grow: unset !important; }

.clearFilterButton {
  margin-bottom: 20px;
  margin-right: 20px; }

.loader {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  width: 100%; }
