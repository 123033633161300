.preview .label {
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-transform: uppercase; }

.preview .image {
  align-items: center;
  border: 1px solid rgba(0, 40, 100, 0.12);
  display: flex;
  height: 400px;
  justify-content: center;
  padding: 0.25rem; }
  .preview .image img {
    display: block;
    max-height: 100%;
    max-width: 100%;
    padding: 10px; }

.preview .info {
  margin: 0 3rem;
  text-align: center; }

.controls {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px; }
