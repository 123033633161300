.noScroll {
  overflow-y: hidden !important; }

.fullWidth {
  background: #ffffff;
  border-radius: 0.1875rem;
  box-sizing: border-box;
  height: 100%;
  max-height: calc(100vh - 5rem);
  max-width: 80rem;
  overflow-y: scroll;
  padding: 1rem;
  padding: 2rem;
  position: relative;
  width: 100%; }
  .fullWidth .close {
    cursor: pointer;
    font-size: 1.25rem;
    position: absolute;
    right: 0.5rem;
    top: 0.75rem; }

:global(.tablet) .fullWidth,
:global(.desktop) .fullWidth,
:global(.bigDesktop) .fullWidth {
  max-height: calc(100vh - 5rem); }
