.mainWrapper {
  position: relative; }

.wrapper {
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px; }
  .wrapper.closed {
    opacity: 0.65; }
  .wrapper:hover {
    background: #e2e2e2; }
  .wrapper h3,
  .wrapper h4 {
    font-size: 0.875rem;
    padding: 0.2rem; }
  .wrapper h3 {
    margin: 0; }
  .wrapper h4 {
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    font-weight: normal;
    justify-content: space-between; }
    .wrapper h4:last-child {
      border: none; }
  .wrapper .details {
    color: #b9b9b9;
    font-size: 0.675rem; }

.editButton {
  float: right;
  position: absolute;
  right: 0.2rem;
  top: 0.2rem;
  z-index: 1; }

:global(.tablet) .wrapper h3,
:global(.tablet) .wrapper h4,
:global(.desktop) .wrapper h3,
:global(.desktop) .wrapper h4,
:global(.bigDesktop) .wrapper h3,
:global(.bigDesktop) .wrapper h4 {
  padding: 0.5rem; }

.isNotActive {
  opacity: 0.45; }
