.colorPicker {
  display: flex; }

.selectedColor {
  background: #3174ad;
  border-radius: 4px;
  height: 38px;
  margin-left: 10px;
  width: 38px; }

.popover {
  position: absolute;
  z-index: 2; }

.cover {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%; }
