.addButton,
.addButtonPlus {
  position: absolute;
  right: 0;
  top: 0; }

.wrapper {
  column-gap: 5px;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  row-gap: 5px; }
  .wrapper::before {
    content: '';
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    padding-bottom: 100%;
    width: 0; }
  .wrapper .tile {
    align-items: center;
    background: #1e7e34;
    border: 1px #ffffff solid;
    cursor: pointer;
    display: flex;
    justify-content: center; }
    .wrapper .tile .productName {
      color: #ffffff;
      font-weight: 600;
      padding: 1rem; }
  .wrapper .disabledTile {
    background-color: #ffffff;
    cursor: default; }
    .wrapper .disabledTile .productName {
      display: none; }
  .wrapper .tile:first-child {
    grid-column: 1 / 1;
    grid-row: 1 / 1; }

:global(.smallMobile) .addButton,
:global(.smallMobile) .addButtonPlus,
:global(.mobile) .addButton,
:global(.mobile) .addButtonPlus {
  top: 47px; }

:global(.smallMobile) .selectWrapper,
:global(.mobile) .selectWrapper {
  margin-top: 30px; }
