.wrapper {
  align-items: center;
  background: #ffffff;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10; }

.unauthorized {
  padding: 3rem;
  text-align: center; }
