.summaryBox .mainBasketTitle {
  font-size: 1.5rem; }

.summaryBox .basketWrapper .product {
  border-bottom: 1px solid #dddddd;
  display: flex;
  height: 59px;
  justify-content: space-between;
  margin-bottom: 10px; }
  .summaryBox .basketWrapper .product .delete {
    align-items: center;
    display: flex;
    justify-content: center; }
  .summaryBox .basketWrapper .product .info {
    display: flex;
    flex: 3;
    flex-direction: column;
    justify-content: center; }
    .summaryBox .basketWrapper .product .info .title {
      padding: 0 10px; }
