.pagination {
  margin: 2rem auto; }
  .pagination ul li {
    display: inline-block;
    list-style: none;
    padding-left: 0; }
    .pagination ul li.selected a {
      background-color: #467fcf;
      border-color: #1e7e34;
      color: #ffffff; }
    .pagination ul li a,
    .pagination ul li span {
      background-color: #ffffff;
      border: 0.1rem solid #ddd;
      color: #2c689c;
      float: left;
      line-height: 1.42857143;
      margin-left: -0.1rem;
      outline: none;
      padding: 0.6rem 1.2rem;
      position: relative;
      text-decoration: none; }
    .pagination ul li a:hover {
      background-color: #467fcf;
      color: #ffffff;
      cursor: pointer; }
