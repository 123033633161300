.wrapper {
  padding: 10px;
  width: 100%; }

.formWrapper {
  padding: 1rem; }

.searchWrapper {
  display: flex; }
  .searchWrapper .selectInput {
    flex: 1; }
  .searchWrapper .searchInput {
    flex: 3;
    margin-bottom: 1rem; }

.scroller {
  border: 0.1rem solid rgba(210, 198, 225, 0.28); }
  .scroller ul {
    height: 15rem;
    list-style: none;
    margin: 0;
    overflow-y: scroll;
    padding: 0; }
    .scroller ul li {
      display: flex;
      justify-content: center;
      padding: 0; }
      .scroller ul li button {
        background: none;
        border: none;
        line-height: 1;
        padding: 0.4rem 0.75rem;
        text-align: left;
        width: 100%; }
        .scroller ul li button:hover {
          background: #cccccc;
          cursor: pointer; }

:global #formio .form-control.selection {
  height: auto;
  min-height: calc(1.5em + 0.75rem + 2px); }
