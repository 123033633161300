.button {
  width: 100%; }

.filters {
  column-gap: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1em; }

:global(.desktop) .filters {
  grid-template-columns: repeat(3, 1fr); }

:global(.bigDesktop) .filters {
  grid-template-columns: repeat(3, 1fr); }

:global(.tablet) .filters {
  grid-template-columns: repeat(3, 1fr); }
