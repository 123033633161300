@keyframes startingAnimation {
  0% {
    opacity: 0;
    transform: translateY(100px); }
  100% {
    opacity: 1; } }

.toast {
  animation: startingAnimation 300ms 1;
  float: right;
  margin-top: 1rem;
  min-width: 300px;
  opacity: 1;
  transition: all 600ms; }
  .toast.hidden {
    opacity: 0;
    transform: translateY(100px); }

.inner {
  border-radius: 0.5rem;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  color: #ffffff;
  font-size: 1rem;
  padding: 0.75rem 2rem;
  text-align: center;
  transition: all 600ms; }

.button {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  margin-left: 0.5rem;
  opacity: 0.5;
  padding: 0; }
  .button:hover {
    opacity: 1; }

.added {
  background: #ffbf66; }

.removed {
  background: #33657e; }

.error {
  background: #e56666; }

.info {
  background: #93a2a8; }

.success {
  background: #5be298; }

.warning {
  background: #ffb45a; }
