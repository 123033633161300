.formBody {
  box-sizing: border-box;
  flex-grow: 1;
  padding: 1rem;
  padding-bottom: 0; }

.withPhoto {
  display: flex; }
  .withPhoto .photo {
    margin-bottom: 1rem;
    margin-right: 2rem; }
  .withPhoto .data {
    flex-grow: 1; }

.spaces {
  background: #f5f7fb6e;
  border-top: 1px solid rgba(0, 40, 100, 0.12); }

.wrapper {
  display: flex; }
  .wrapper .options {
    align-self: auto;
    margin: 0;
    padding: 1rem; }
  .wrapper .data .errorMessage {
    color: red; }

.alertWrapper {
  margin: 0 1rem; }

.radioWrapper {
  display: flex;
  padding: 0.375rem 0; }
  .radioWrapper label {
    margin-right: 1rem; }

:global(.timePickerWrapper) {
  align-items: flex-start;
  display: flex; }

:global(.customCheckBox) {
  top: 5px; }

.managerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .managerWrapper .formBody .titleContent {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem; }
    .managerWrapper .formBody .titleContent .title {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      padding-left: 1rem; }
    .managerWrapper .formBody .titleContent .photo {
      margin: auto; }

:global(.tablet) .managerWrapper .formBody .titleContent,
:global(.desktop) .managerWrapper .formBody .titleContent,
:global(.bigDesktop) .managerWrapper .formBody .titleContent {
  flex-direction: row;
  font-size: 2rem; }
