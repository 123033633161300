.info {
  display: inline;
  position: relative; }
  .info i {
    color: #bbbbbb;
    cursor: pointer;
    position: relative;
    top: 2px; }
  .info .text {
    background: #ececec;
    border: 1px solid rgba(0, 40, 100, 0.12);
    border-radius: 0.25rem;
    bottom: 20px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    display: none;
    font-size: 0.75rem;
    left: 0;
    padding: 0.75rem;
    position: absolute;
    transform: translateX(-50%);
    width: 400px; }
  .info:hover .text {
    display: block; }
