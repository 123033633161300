.input,
.value {
  border: 1px solid transparent;
  font-size: inherit;
  line-height: 1.6;
  padding: 0.375rem 0.75rem; }

.value {
  border-bottom-color: rgba(0, 40, 100, 0.12);
  word-break: break-all; }
  .value:hover {
    background: #ffffff;
    cursor: pointer; }

.input {
  border-color: rgba(0, 40, 100, 0.12);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  display: block;
  width: 100%; }
  .input::placeholder {
    color: #bdccd9; }

.wrapper {
  font-size: 0.9375rem;
  position: relative; }
  .wrapper.medium {
    font-size: 1.5rem; }
  .wrapper.big {
    font-size: 2rem; }

.error {
  border-radius: 3px;
  color: #cd201f;
  font-size: 0.875rem;
  padding: 0 0.5em;
  position: absolute;
  right: 9px;
  top: 9px; }
