.title {
  font-size: 3em; }

.center {
  align-items: center;
  display: flex;
  justify-content: center; }

.bigIcon {
  margin: 2rem 0; }
