.wrapper {
  margin-bottom: 2rem;
  margin-top: 2rem;
  width: 100%; }

.timeErrorMessage {
  color: red;
  text-align: center; }

.duration {
  background: #ffffff;
  border: 1px solid #b9b9b9;
  border-radius: 2px;
  display: flex;
  height: 30px;
  position: relative; }
  .duration .marker {
    background: #467fcf;
    border-radius: 10px;
    bottom: 11px;
    left: 33.3333%;
    position: absolute;
    top: 11px;
    width: 30%; }
  .duration .hour {
    position: relative; }
    .duration .hour:after {
      background: rgba(32, 32, 32, 0.466);
      content: '';
      display: block;
      height: 100%;
      left: 50%;
      position: absolute;
      top: 0;
      width: 1px; }
    .duration .hour:before {
      bottom: -20px;
      color: #b9b9b9;
      content: attr(data-title);
      font-size: 0.675rem;
      left: 50%;
      position: absolute;
      transform: translateX(-50%); }
  .duration .even::before {
    bottom: unset;
    top: -20px; }
  .duration .point {
    appearance: none;
    background: #ffffff;
    border-radius: 4px 4px 100% 100%;
    box-shadow: 0 2px 10px rgba(32, 32, 32, 0.466);
    cursor: pointer;
    height: 22px;
    left: 33.3333%;
    position: absolute;
    top: 7px;
    transform: translate(-50%, -50%);
    user-select: none;
    width: 12px; }
    .duration .point:hover {
      background: #fdffb1; }

.info {
  display: flex;
  justify-content: space-between; }
  .info h4 {
    color: #b9b9b9;
    font-size: 0.75rem;
    margin: 0;
    padding: 0; }
  .info > div:last-child {
    text-align: right; }

.days {
  font-size: 0.875rem;
  margin: 0 1rem; }

.button {
  background: #e2e2e2;
  border: none;
  border-radius: 0.1875rem;
  color: #b9b9b9;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  width: 30px; }

.formGroup:first-child {
  margin: auto;
  margin-top: 10px;
  width: auto;
  width: 68px; }

.formGroup:nth-of-type(2) {
  top: 40px !important; }

:global(.timePicker) {
  margin-bottom: 20px;
  top: 40px; }
