.title {
  font-size: 3rem; }

.description {
  font-size: 1rem;
  padding: 1rem 0; }

.center {
  align-items: center;
  display: flex;
  justify-content: center; }

.section {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.bigIcon {
  margin: 4rem 0; }

.buttonContainer {
  flex: 1;
  justify-content: space-around; }
