.scroller {
  padding: 0.5rem 0.75rem; }
  .scroller .title {
    font-size: 0.875rem;
    text-align: center; }
  .scroller ul {
    height: 9rem;
    list-style: none;
    margin: 0;
    overflow-y: scroll;
    padding: 0; }
    .scroller ul li {
      display: flex;
      justify-content: center;
      padding: 0; }
      .scroller ul li button {
        background: none;
        border: none;
        line-height: 1;
        padding: 0.4rem 0.3rem; }
        .scroller ul li button:hover {
          background: #cccccc;
          cursor: pointer; }
