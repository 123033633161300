.picker {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 0.2rem;
  display: inline-flex;
  left: 0;
  position: absolute;
  text-align: right;
  top: 4rem;
  z-index: 1; }
