.image {
  align-items: center;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  height: 180px;
  justify-content: center;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 180px; }
  .image .change {
    background: rgba(255, 255, 255, 0.75);
    bottom: 0;
    left: 0;
    padding: 0.5rem;
    position: absolute;
    right: 0; }
  .image .noImage {
    color: #c5c5c5;
    font-style: italic; }
  .image .img {
    display: block;
    max-height: 100%;
    max-width: 100%; }
