.modal {
  background: rgba(32, 32, 32, 0.466);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999; }

:global(.tablet) .body,
:global(.desktop) .body,
:global(.bigDesktop) .body {
  width: calc(100vw - 10rem); }

.body {
  display: flex;
  height: auto;
  justify-content: space-between;
  margin: auto;
  margin-top: 1rem;
  max-height: calc(100vh - 5rem);
  max-width: 110rem;
  width: calc(100vw - 2rem); }
  .body .left {
    background: #fbfcfd;
    width: 28rem; }
  .body .left,
  .body .right {
    border-radius: 0.1875rem;
    box-sizing: border-box;
    height: 100%;
    overflow-y: scroll;
    padding: 1rem;
    width: 100%; }
  .body .right {
    background: #ffffff;
    max-height: 50rem;
    padding: 2rem;
    position: relative; }
    .body .right .close {
      cursor: pointer;
      font-size: 1.25rem;
      position: absolute;
      right: 0.5rem;
      top: 0.75rem; }

.error {
  color: #cd201f;
  font-size: 0.675rem;
  text-align: right; }

.preview {
  margin-top: 1.5rem; }
  .preview .switch {
    color: #b9b9b9;
    cursor: pointer;
    font-size: 0.675rem;
    font-weight: bold;
    margin-bottom: 1.5rem; }

.advanced {
  background: #f3f3f3;
  border: 0.1rem solid #b9b9b9;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  margin-bottom: 1rem;
  padding: 1rem; }

.useName {
  color: #b9b9b9;
  font-size: 0.75rem; }
  .useName span {
    color: #467fcf;
    cursor: pointer;
    text-decoration: underline; }

.cyclic {
  display: flex; }
  .cyclic label {
    margin-right: 1rem; }

.upsell {
  align-items: center;
  display: flex;
  font-size: 0.75rem;
  justify-content: flex-end;
  margin-top: 0.5rem; }
  .upsell input {
    margin-right: 0.25rem; }
