.wrapper {
  background: rgba(32, 32, 32, 0.466);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999; }
  .wrapper .title {
    color: #ffffff;
    display: inline-block;
    left: calc(50% - 100px);
    position: absolute;
    top: calc(50% + 50px); }
