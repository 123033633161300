.card {
  margin-top: 20px;
  padding: 1rem; }

.select.disabled {
  background: #f8f9fa;
  color: #cccccc;
  pointer-events: none; }

.ratio {
  align-items: center;
  display: flex; }
  .ratio .input {
    flex-grow: 1; }
  .ratio .number {
    border: 1px solid rgba(0, 40, 100, 0.12);
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    margin-left: 10px;
    padding: 10px;
    text-align: right;
    width: 100px; }

.userData {
  background: #f5f5f5 !important;
  padding: 15px; }

.datepicker {
  padding-left: 0.75rem; }

.slider {
  -webkit-appearance: none;
  appearance: none;
  background: #f5f5f5;
  border-radius: 10px;
  height: 6px;
  outline: none;
  width: 100%; }
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: #216ba5;
    border-radius: 6px;
    cursor: pointer;
    height: 40px;
    width: 40px; }
  .slider::-moz-range-thumb {
    background: #216ba5;
    border-radius: 6px;
    cursor: pointer;
    height: 40px;
    width: 40px; }

.loader {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  width: 100%; }

.wrapper .titleWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; }
  .wrapper .titleWrapper .title {
    display: inline-block;
    font-weight: bold; }
  .wrapper .titleWrapper .customButton {
    margin-left: 10px; }

.info {
  text-align: center; }

:global(.smallMobile) .wrapper .titleWrapper,
:global(.mobile) .wrapper .titleWrapper {
  flex-direction: column; }

:global(.smallMobile) .wrapper .customButton,
:global(.mobile) .wrapper .customButton {
  margin: 0 10px 0 0; }

:global(.smallMobile) .wrapper .buttonList,
:global(.mobile) .wrapper .buttonList {
  display: flex;
  justify-content: space-between; }
