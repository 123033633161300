.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.title {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center; }

.cancelButton {
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 10px; }
