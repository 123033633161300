.wrapper {
  align-items: center;
  border: 0.1rem solid #000000;
  display: flex;
  height: 2.4rem;
  justify-content: space-between;
  line-height: 2.2rem;
  margin: auto;
  width: 2.4rem; }

.smallWrapper {
  align-items: center;
  border: 0.1rem solid #000000;
  display: flex;
  height: 2.4rem;
  justify-content: space-between;
  line-height: 2.2rem;
  margin: auto;
  width: 2.4rem; }

.value {
  box-shadow: none;
  cursor: pointer;
  font-size: 15px;
  height: 100%;
  margin: auto;
  outline: none;
  text-align: center; }
  .value:active {
    box-shadow: none; }

.additionalButton {
  align-items: center;
  display: flex;
  font-size: 2rem;
  height: 100%;
  justify-content: center;
  text-align: center;
  user-select: none;
  width: 2rem; }
  .additionalButton:hover {
    cursor: pointer; }
