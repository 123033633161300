.navigation {
  align-items: center;
  display: flex;
  justify-content: space-between; }

.navigationWrapper {
  display: flex;
  flex-direction: column;
  top: 10px; }

.mobileNavigationWrapper {
  display: flex;
  justify-content: space-between; }
  .mobileNavigationWrapper .hamburgerButton {
    margin: 10px; }

:global(.mobile) .navigation {
  align-items: inherit;
  flex-direction: column;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px; }

:global(.smallMobile) .navigation {
  align-items: inherit;
  flex-direction: column;
  justify-content: flex-start; }

:global(.desktop) .navigationWrapper,
:global(.bigDesktop) .navigationWrapper {
  flex-direction: row; }

:global(.smallMobile) .mobileNavigationWrapper,
:global(.mobile) .mobileNavigationWrapper {
  margin: 0; }
