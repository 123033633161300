.item :global(.card-options) {
  white-space: nowrap; }
  .item :global(.card-options) a,
  .item :global(.card-options) a:hover {
    color: #ffffff; }

.item .color {
  background: #3174ad;
  border-radius: 0.25rem;
  height: 1.875rem;
  margin-right: 0.625rem;
  width: 1.875rem; }

.item .title {
  margin: 0; }

.item .body {
  padding: 0; }

.spaces :global(.list-group-item) {
  align-items: center;
  border: none;
  border-bottom: 1px solid rgba(0, 40, 100, 0.12);
  display: flex;
  font-size: 0.875rem;
  margin-bottom: 0;
  padding: 0.5rem 1.5rem; }
  .spaces :global(.list-group-item):last-child {
    border: none; }

.spaces :global(.avatar) {
  margin-right: 0.5rem; }

.loader {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  width: 100%; }

.buttonList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px; }
