.filterTitle {
  margin: 0; }

.color {
  background: #3174ad;
  border-radius: 0.25rem;
  height: 1.875rem;
  margin-right: 0.625rem;
  width: 1.875rem; }

.formGroup {
  height: 100%;
  max-width: 280px;
  width: 100%; }
  .formGroup > div {
    height: 100%; }

.header {
  display: flex;
  justify-content: space-around; }
