.center {
  display: flex;
  justify-content: center; }

.index {
  padding: 14px; }

.downloadButton {
  bottom: 30px;
  position: fixed;
  right: 30px; }
