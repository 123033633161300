@import url(react-datepicker/dist/react-datepicker-cssmodules.css);
body {
  font-size: 16px;
  height: auto !important;
  line-height: 1.5;
  position: absolute; }

body,
button,
input,
textarea {
  font: 1.6rem sans-serif; }

body {
  overflow-y: scroll; }

body,
h1,
h2,
h3,
h4,
h5,
p,
li,
ul {
  margin: 0;
  padding: 0; }

a {
  text-decoration: none; }

body {
  background: #ffffff !important; }

:global(.header) {
  background: whitesmoke !important;
  border: none !important; }
