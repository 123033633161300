.background {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%; }

.modal {
  background: #ffffff;
  border-radius: 0.5rem;
  bottom: 0;
  box-shadow: 0.0625rem 0.125rem 2rem rgba(210, 198, 225, 0.28);
  box-sizing: border-box;
  height: auto;
  left: 50%;
  margin: 1rem auto;
  max-height: calc(100vh - 5rem);
  max-width: 90vw;
  min-height: 10rem;
  min-width: 19rem;
  overflow-x: auto;
  padding: 1.5rem;
  position: fixed;
  top: 0;
  transform: translate(-50%, 0);
  width: 90%;
  z-index: 999; }

:global(.tablet) .modal,
:global(.desktop) .modal,
:global(.bigDesktop) .modal {
  min-width: 25rem;
  width: 90%; }
