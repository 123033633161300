.container {
  position: relative; }
  .container .group {
    position: relative; }
    .container .group input[readonly] {
      background-color: #ffffff; }
    .container .group input[disabled] {
      background-color: #f8f9fa; }
    .container .group span {
      bottom: -1.1rem;
      position: absolute;
      text-align: right; }
  .container .datePicker {
    position: absolute;
    top: 4rem;
    z-index: 1; }

.clear {
  background: none;
  border: none;
  bottom: 3px;
  cursor: pointer;
  display: block;
  height: 2rem;
  outline: none;
  position: absolute;
  right: 3px;
  width: 2rem; }
  .clear:before, .clear:after {
    background: rgba(0, 40, 100, 0.42);
    content: '';
    display: block;
    height: 50%;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 1px; }
  .clear:before {
    transform: translateY(-50%) rotate(45deg); }
  .clear:after {
    transform: translateY(-50%) rotate(-45deg); }
