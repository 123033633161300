.wrapper {
  margin: 0 !important;
  padding: 0.375rem 0.75rem;
  width: 100%; }
  .wrapper.disabled {
    background: #f8f9fa;
    color: #cccccc;
    pointer-events: none; }

.timeSlots {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  width: 100%; }
  .timeSlots li {
    box-sizing: border-box;
    min-width: 75px;
    padding: 5px;
    width: 12.5%; }

.button {
  width: 100%; }
