.center {
  display: flex;
  justify-content: center; }

.loader {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  width: 100%; }
