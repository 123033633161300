.days {
  display: flex;
  flex-wrap: wrap; }

.day {
  border: 1px solid #b9b9b9;
  border-radius: 0.1875rem;
  cursor: pointer;
  flex-grow: 1;
  font-size: 0.8125rem;
  line-height: 1.6;
  margin: 0.25rem;
  text-align: center; }
  .day:first-child {
    margin-left: 0; }
  .day:last-child {
    margin-right: 0; }
  .day.selected {
    background: #467fcf;
    border-color: #467fcf;
    color: #ffffff; }

:global(.tablet) .day,
:global(.desktop) .day,
:global(.bigDesktop) .day {
  margin: 0.25rem;
  padding: 0.5rem 0.75rem; }
